const config = {
  "broker": "1",
  "name": "Taiwan",
  "registrationBundle": "taiwanese",
  "defaultCountryName": "Taiwan",
  "defaultCountryCode": "TW",
  "fxgeoip_code": "TW",
  languageCode: 'tw',
  languageName:'Taiwanese',
  spokenLanguageCode:'tw',
  direction:'ltr',
  footerDisclaimer:'1045',
  brand: 'iforex'
};
module.exports = config;